import React from 'react'
import ProductTemplate from 'components/templates/ProductTemplate/ProductTemplate'

export default function UpdateInChargeTrainingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const products = {
    0: {
      productId: 'UICT',
      productName: 'Formazione preposto AGGIORNAMENTO',
      productImages: {
        0: ['courses/corso-formazione-preposto.jpg', 'corso formazione preposto aggiornamento'],
      },
      productSize: 'Un corso formazione',
      productWeight: 0,
      productPrice: '5500',
      productPriceDesc: null,
      price: 'price_1J0oCULde8wm4I0oiNrV8y73',
    },
  }

  const description =
    '<strong>OBBLIGATORIETÀ:</strong> Obbligatorio per tutti i preposti di qualsiasi tipologia di azienda ogni 5 anni.<br/><br/> <strong>DURATA:</strong> 6 h<br/>'

  const presentation = {
    title: 'Formazione preposto AGGIORNAMENTO',
    description:
      '<strong>COME SI SVOLGE:</strong> ll corso di formazione si svolge online in modalità e-learning. All’acquisto vengono inviati link e credenziali con i quali collegarsi in qualsiasi momento, anche in più volte, dal proprio PC o dallo smart phone. Alla fine del corso è previsto un test finale che può essere sostenuto più volte, superato il quale viene rilasciato regolare attestato di avvenuta formazione, accreditato da Regione, in conformità al D. Lgs. 81/08 ed all’Accordo Stato Regioni del 21 dicembre 2011, che definisce durata e contenuti minimi del corso.<br/><br/> <strong>DESCRIZIONE:</strong> L’aggiornamento formazione dei preposti, come previsto dall’Accordo Stato Regioni del 21.12.2011, sostituisce integralmente quella prevista per i lavoratori trattando Approfondimenti giuridico-normativi, Gestione e organizzazione della sicurezza aziendale, Individuazione e valutazione dei rischi, Comunicazione e formazione.',
    photo: 'corso-formazione-preposti-diagramma.jpg',
  }

  return (
    <>
      <ProductTemplate
        seoKeywords={seoKeywords}
        products={products}
        description={description}
        presentation={presentation}
      />
    </>
  )
}
